export default {
  text: '#040402', // good
  primary: '#173664',
  primaryLight: '#8D97A1',
  primaryDark: '#173664',
  secondary: '#8D97A1', // good
  secondaryLight: '#8D97A1',
  secondaryDark: '#040402', //  good
  background: '#f7f7f7',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#040402' // good
}
