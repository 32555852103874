export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        filter: 'unset',
        maxHeight: ['70px', '90px', '90px', '']
        // maxWidth: ['', '', 'unset', 'unset'],
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: ['70px', '90px', '90px', '']
      }
    },

    '.container': {
      '.smallNavMenu': {
        '.navItem': {
          color: 'white',
          a: {
            fontSize: '1.1rem',
            fontFamily: 'body',
            textTransform: 'capitalize',
            ':hover': {
              textDecoration: 'underline'
            }
          }
        }
      }
    },
    '.containerScrolled': {
      '.smallNavMenu': {
        '.navItem': {
          color: 'dark',
          a: {
            textTransform: 'capitalize',
            fontSize: '1.1rem',
            fontFamily: 'body',
            ':hover': {
              textDecoration: 'underline'
            }
          }
        }
      }
    },

    '.hamburger': {
      borderRadius: '10px',
      backgroundColor: 'rgba(255,255,255,0.6)'
    },
    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        textTransform: 'none',
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    },

    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      '.navItem': {
        a: {
          fontSize: '1.2rem',
          fontFamily: 'body'
        }
      },
      '.navMenuLogo': {
        maxHeight: '150px',
        marginBottom: '1.5rem',
        borderBottom: '1px solid',
        paddingBottom: '1.5rem',
        display: 'flex',
        img: {
          maxHeight: '150px',
          width: 'auto',
          objectFit: 'contain'
        },
        ':hover': {
          // transform: 'rotate(360deg)',
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        margin: '0rem 1rem',
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    }
  },

  footer: {
    padding: ['2rem 1rem 6rem', '', '2rem'],
    '.logo': {},
    borderTop: 'solid 2px',
    borderColor: 'primary',
    '.multiButtonContainer': {
      a: {
        variant: 'buttons.secondary'
      }
    },
    '.gonationLogo': {
      width: 'fit-content'
    },
    '.socialIconsContainer': {
      'svg path': {
        fill: 'primary'
      }
    },
    '.copyright': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1rem',
      '::after': {
        content: "'Photos By Walker Studios LLC'",
        fontSize: '0.8rem',
        opacity: '0.8',
        textAlign: 'center'
      }
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],
    fontWeight: '800',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'primaryDark',
    textTransform: 'uppercase',
    order: '1'
  },
  subtitle: {
    color: 'secondaryDark',
    opacity: '0.5',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.1rem', '1.25rem', '1.5rem'],
    marginBottom: '1.5rem',
    order: '2',
    // fontFamily: 'display',
    fontWeight: 'bold',
    fontFamily: 'body',

    border: 'none'
  },
  text: {
    textAlign: 'left',
    lineHeight: '1.75',
    order: '3',
    p: {
      lineHeight: '1.75',
      textAlign: 'left',
      fontSize: 'inherit'
    }
  },

  sideBySide1: {
    padding: '0rem 1rem 1rem',
    '.lazyload-wrapper': {
      maxHeight: '75vh'
    },
    '.content': {
      // variant: 'customVariants.borderCorners',
      position: 'relative',
      alignItems: 'flex-start'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },

    '.linksContainer': {
      mt: '2rem'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.imageContainer': {
      height: ['', '', '75vh']
    },
    '.content': {
      // variant: 'customVariants.borderCorners',
      order: '2',
      position: 'relative',
      alignItems: 'flex-start'
    },

    '.linksContainer': {
      mt: '2rem'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageTestimonials: {
    '.menuItemDescription, .menuItemNameContainer': {
      textAlign: 'center'
    },
    '.menuItemDescription': {
      fontSize: '18px'
    },
    '.menuItemContentContainer': {
      maxWidth: 600,
      margin: 'auto'
    }
  },

  homepageHero: {
    marginTop: '1rem',
    '.section': {
      margin: 'auto auto',
      padding: '0.5rem'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      color: 'primary',
      textShadow: '1px 1px 20px black'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',

    justifyContent: 'center',
    'h1.title': {
      textAlign: 'center',
      justifyContent: 'center',
      width: '100%'
    },

    '.linksContainer': {
      justifyContent: 'center'
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageContact: {
    variant: 'customVariants.homepageHero',
    marginTop: '0rem',
    '.linksContainer': {
      justifyContent: 'center'
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'secondary',
      fontFamily: 'display'
    },
    '.text': {
      fontFamily: 'body',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['primary'],
      color: 'white'
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },

    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageTestimonials: {
    padding: ['10vh 1rem', '', '20vh 2rem'],
    maxWidth: 'unset',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/w_1350,c_fit,fl_lossy,f_auto,q_auto/gonation.data.prod/fjhc5ksfgunhyiotvgj0)',
    backgroundSize: '75% 75%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#17366405',

    '.menuSectionTitle': {
      margin: '1rem',
      fontSize: ['2rem', '', '', '3rem'],
      textTransform: 'uppercase',
      fontWeight: 800,
      textAlign: 'left',
      color: '#28327f',

      textAlign: 'center',
      justifyContent: 'center'
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2rem'],
      opacity: '0.6',
      textAlign: 'center',
      margin: ['1.5rem', '', '4rem 2rem 2rem']
    },

    '.menuItemNameContainer': {
      order: '4',
      marginTop: '1rem',
      color: 'secondaryDark',
      textAlign: 'center'
    },

    '.menuItemDescription': {
      color: 'primary',
      fontSize: ['1rem', '', '1.25rem'],
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem']
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menuCTAS: {
    '.ctaButton, .secondaryCtaButton': {
      variant: 'buttons.primary',
      ':hover': {
        backgroundColor: 'transparent',
        color: 'text'
      }
      // padding: '1rem',
      // display: 'flex'
    }
  },
  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem',
      ':hover': {
        color: 'primary'
      }
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.linksContainer': {
      order: 'unset',
      mb: '2rem',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    },
    '> .text': {
      maxWidth: '700px',
      mb: '2rem',
      '*': {
        color: 'white'
      }
    },

    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center',
      color: 'white'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
